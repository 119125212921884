.f-wrapper{
  
    background-color: black;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;

}

@keyframes pulsee {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  

.mail{
    animation: pulsee 2s infinite;
    color: gold;
}


.f-container{
    color: white;

}

@media only screen and (max-width: 1024px) {
    .f-wrapper{
  
        background-color: black;
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
    
    }
    .f-container{
        justify-content: center;
    
    }

}