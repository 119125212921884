.custom-table{
	margin-right:14px;
	margin-left:0px;

    /* padding-right:2% ; */
	justify-content:center;
	align-items:center;
	min-height:100vh;
	
}



.table{
	/* position: fixed; */

	width: 100%;
  


	border:1px solid black;
}

.table-header{
    /* display:flex; */
	width:100%;
	background:#000;
    color:white;
	
}


.table-data {
	flex: 1 1 20%;
	text-align:center;
    color:white;
    background:black;
}

