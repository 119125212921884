
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Share Tech Mono', monospace;
  scroll-behavior: smooth;
  /* overflow-x: hidden; */
}
a {
  color: inherit;
  text-decoration: none;
}
.paddings {
  padding: .5rem;
}

.innerWidth {
  width: 100%;
}

.flexCenter {
  display: flex;
  row-gap: 1rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flexColCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flexColStart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.flexColEnd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.primaryText {
  color: #1f3e72;
  font-weight: bold;
  font-size: 2rem;
}

.secondaryText {
  color: rgb(140 139 139);
  font-size: 0.9rem;
}


.button{
  font-weight: 500;
  padding: 0.6rem 1.4rem;
  color: white;
  background: var(--blue-gradient);
  border:none;
  border-radius: 4px;
  transition: all 300ms ease-in;

}




/* media queries */
@media (min-width: 1536px) {
  .innerWidth {
    max-width: 1280px;
    margin: auto;
  }
}

@media (min-width: 640px) {
  .paddings {
    padding: 2rem;
  }
}

@media (min-width: 768px) {
  .paddings {
    padding: 2rem;
  }
}

@media (min-width: 1280px) {
  .paddings {
    padding-left: 12rem;
    padding-right: 12rem;
  }
}

@media (min-width: 1900px) {
  .paddings {
    padding: 4rem;
  }
}

@media (max-width: 640px) {
 
}