 .logo-css{
  text-decoration: none;
  color:gold;
  -webkit-box-reflect: below -120px linear-gradient
  (transparent, black);
  text-shadow: 0 0 10px orange,
               0 0 40px orange,
               0 0 80px orange,
               0 0 160px orange,
               0 0 240px orange;
               filter:url(#fire);
 }

 .rage{
	text-decoration: none;
	color:red;
	-webkit-box-reflect: below -120px linear-gradient
	(transparent, black);
	text-shadow: 0 0 10px red,
				 0 0 40px red,
				 0 0 80px red,
				 0 0 160px red,
				 0 0 240px red;
				 filter:url(#fire);
 }

 .logo-fire {
    display: none;
 }



 .h-wrapper{
    background:black;
    color: white;
    z-index: 99;
}

.f-wrapper{
    background:black;
    color: white;
    z-index: 99;
}

.h-container{
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: white;
	/* height: 70px; */

}

nav{
    margin-left: 40%;
	padding-left: 5rem;
}
nav a {
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none;
}

nav a:hover {
	color: gold;
}

header .nav-btn {
	/* padding: 5px; */
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: 1;
	opacity: 0;
	font-size: 1.8rem;
}
header .nav-close-btn {
	/* padding: 5px; */
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: visible;
	opacity: 0;
	font-size: 1.8rem;
}

.log-in{
    color: gold;
    cursor: pointer;
}


header div,
nav {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1024px) {
	header{
		margin-left: 1rem;
	}
	header .nav-btn {
		visibility: visible;
		opacity: 1;
		margin-right: 1rem;
	}

	header nav {
        z-index: 99;
		position: fixed;
		top: -100vh;
		left: 0;
		height: 40%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: black;
        opacity: 0.9;
		transition: 1s;
		padding-left: 0rem;
	}

	header .responsive_nav {
		transform: translateY(100vh);
	}

    nav{
        margin-left: 0;
		
    }
	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
        visibility: visible;
	opacity: 1;
	}

	.links{
		flex-direction: column;
	}

	nav a {
		font-size: 1.5rem;
		padding: 10px;
	}

    
    .log-in{
        font-size: 0.6rem;
    }

	.username{
		font-size: 0.7rem;
	}

	



}