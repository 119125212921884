
h2{
    font-size:2em;
    color:black;
    text-align: center;
}


.login-body{
    
    display:flex;
    justify-content:center;
    align-items: center;
    width: 100%;
    min-height: 80vh;
    
}


.login-box{
    position:relative;
    
    width: 400px;
    height:auto;
    background: rgba(255, 255, 255, 0.7);
    border:2px solid rgba(255,255,255,.5);
    border-radius:20px;
    backdrop-filter: blur(20px);
    box-shadow:0 0 30px rgba(0,0,0,.5);
    display: flex;
    justify-content: center;
    align-items: center;

}

.login-box a {
    color: black;
}

.login-body button{
    width:100%;
    height: 40px;
    background:rgba(56, 55, 55, 0.5);
    border:none;
    outline:none;
    border-radius: 40px;
    cursor: pointer;
    font-size: 1em;
    color: gold;
    font-weight: 500;
} 



.input-box{
    position: relative;
    width:100%;
    height:50px;
    border-bottom:2px solid black;
    margin: 30px 0;
    color: black;
}

.input-box label{
    position:absolute;
    top:50%;
    left:5px;
    transform: translateY(-50%);
    font-size: 1em;
    font-weight: 500;
    color: black;
    pointer-events: none;
    transition: .5s;
}

.input-box input:focus~label,.input-box input:valid~label{
    top:-5px;
}

.input-box input{
    width:100%;
    height: 100%;
    background: transparent;
    border:none;
    outline:none;
    font-size: 1em;
    color: black;
    padding: 0 35px 0 5px;
}

.input-box select{
  width: 300px;
  padding: 12px;
  border: 0 !important;
  /* background-color: black; */
  /* background: rgba(0, 0, 0, 0.1); */
  /* needed */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* SVG background image */
  /* background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E"); */
    background-size: .6em;
    background-position: calc(100% - 1.3em) center;
    background-repeat: no-repeat;
    color:black;
    font-size: 1em;
    font-weight: 500;
    margin-right: 5px;
}

.input-box select option{
    color: white;
    background-color: black;
}

.input-box .icon{
    position: absolute;
    right:8px;
    font-size:1.2em;
    color:gold;
    line-height:48px;
}

 

.remember-forgot label input {
    margin-right: 3px;
    accent-color: pink;
}

.remember-forgot a {
    color: white;
    text-decoration: none;
}

.remember-forgot a:hover{
    text-decoration: underline;
}


.register-link{
    font-size: .9em;
    color:black;
    text-align: center;
    margin:25px 0 10px;
}

.register-link p a{
    color:black;
    text-align: none;
    font-weight: 600;
}

.register-link p a:hover{
    text-decoration: underline;
    color: gold;
}
.Admin-box{
    display:flex;
    justify-content:center;
    align-items: center;
    width: 100%;
    
}

.Admin-box button{
    width:100%;
    height: 40px;
    background:rgba(56, 55, 55, 0.5);
    border:none;
    outline:none;
    border-radius: 40px;
    cursor: pointer;
    font-size: 1em;
    color: gold;
    font-weight: 500;
} 

.error{
  text-align: center;
  color: black;
  padding: 10px;
}

@media(max-width:360px){
    .login-box{
        width: 100%;
        height: 100vh;
    }

    .input-box{
        width:290px;
    }
}

