/* body{
    background-color: black;
    color:white;
} */

.aboutbody {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;


}
p{
    padding: 1rem;
}

h1{
    display: flex;
    justify-content: center;
    align-items: center;
    color: gold;
    padding: 1rem;
}

.about-text {
    background-color: black;
    color: white;
    border: 2px solid rgba(255, 255, 255, .5);
    border-radius: 20px;
    padding: 1rem;
    width: 60%;
    min-height: 80vh;
    text-align: justify;

}


@media only screen and (max-width: 1024px) {

	.about-text {
 
        width: 100%;
      
    
    }


}

